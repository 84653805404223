import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../authentication/authentication.service";
import { BehaviorSubject, Observable, Subject, of, forkJoin } from "rxjs";
import {
	BOOKING,
	LESSON_PACKAGE,
	MATCHES,
	DELETE_REPEAT_BOOKING,
	PIPEDRIVE_BOOKING,
	TEACHER_PUBLIC,
	LEAD_BOOKING
} from "../../constants/connection-constants";
import { ButtonType } from "src/app/components/call-room/data/ButtonType";
import { PopupService } from "../popup/popup.service";
import { Router } from "@angular/router";
import { PlatformService } from "src/app/platform/platform.service";
import { RoomStateStorage } from "src/app/components/call-room/service/storage/RoomStateStorage";
import { NavigationExtras } from "@angular/router";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { ActivationStatus } from "src/app/students/students.service";
import { StudentPackageLesson } from "src/app/types/studentPackageLesson.type";
import { environment } from "@env/environment";
import { Booking, BookingResponse } from "@data-types/booking.type";

type GetPastBookingsData = {
	userId: string;
	page: string;
	month: number;
	year: number;
	limit?: string;
	studentId?: string;
	status?: string;
	lessonType?: "trial" | "regular";
};

@Injectable({
	providedIn: "root"
})
export class LessonsService {
	updateBookingList$ = new Subject();
	static startSession$ = new BehaviorSubject(false);
	constructor(
		private http: HttpClient,
		private auth: AuthenticationService,
		private popupService: PopupService,
		private router: Router,
		private platformService: PlatformService,
		private roomState: RoomStateStorage
	) {}

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken
			})
		};
	}

	getLessonPackageByStudentEmail(
		email: string
	): Observable<StudentPackageLesson> {
		const url = LESSON_PACKAGE.replace(":email", email);
		return this.http
			.get<StudentPackageLesson>(url, this.authHttpOptions)
			.pipe(map((lessonPackage) => lessonPackage[0]));
	}

	getLessonPackage(email: string): Observable<StudentPackageLesson> {
		const url = LESSON_PACKAGE.replace(":email", email);
		return this.http
			.get<StudentPackageLesson[]>(url, this.authHttpOptions)
			.pipe(
				map((lessonPackages) =>
					lessonPackages.find((lessonPackage) => lessonPackage)
				)
			);
	}

	getRegularStudents(userId: string): Observable<any> {
		return this.http.get(
			`${MATCHES}/${userId}/students?limit=500&page=1`,
			this.authHttpOptions
		);
	}

	getActiveRegularStudents(userId: string): Observable<any> {
		return this.getRegularStudents(userId).pipe(
			mergeMap((students: any) => {
				// Filter initially to get only activated students with valid names
				const filteredStudents = students?.list?.filter(
					(student: any) =>
						student.name.trim() &&
						student.name !== "null null" &&
						student.name.trim() !== "null" &&
						student.activationStatus === ActivationStatus.ACTIVATED
				);

				// Get lesson package status for each student

				const lessonPackageObservables = filteredStudents.map(
					(student: any) =>
						this.getLessonPackageByStudentEmail(student.email).pipe(
							tap((lessonPackage: any) => {
								console.log(lessonPackage);
							}),
							map((lessonPackage: any) => ({
								...student,
								lessonPackageStatus: lessonPackage.active
							})),
							catchError((error) => {
								console.error(
									`Failed to get lesson package for ${student.email}`,
									error
								);
								return of({
									...student,
									lessonPackageStatus: false
								});
							})
						)
				);

				return forkJoin(lessonPackageObservables).pipe(
					map((studentsWithPackages: any[]) => {
						const activeStudents = studentsWithPackages.filter(
							(student: any) =>
								student.lessonPackageStatus === true
						);
						return {
							...students,
							list: activeStudents
						};
					})
				);
			})
		);
	}
	addBooking(bookingData: any): Observable<any> {
		return this.http.post(`${BOOKING}`, bookingData, this.authHttpOptions);
	}

	getBookings(
		userId: string,
		page: string,
		limit: string,
		past?: boolean
	): Observable<any> {
		const currentTime: Date = new Date();
		const oneHour = 60 * 60 * 1000;
		const oneDay = 24 * oneHour;
		const oneYearAgo: Date = new Date(currentTime.getTime() - 365 * oneDay);

		const oneHourAgo: Date = new Date(currentTime.getTime() - oneHour);
		const startTime: string = (
			past ? oneYearAgo : oneHourAgo
		).toISOString();
		// const twentyFourHoursAgo: Date = new Date(currentTime.getTime() - oneDay);
		// const startTime: string = (
		// 	past ? oneYearAgo : twentyFourHoursAgo
		// ).toISOString();

		const todaysDate = new Date();

		past
			? todaysDate.setDate(todaysDate.getDate())
			: todaysDate.setDate(todaysDate.getDate() + 365);
		const endTime = todaysDate.toISOString();

		let url = `${BOOKING}/${userId}?startTime=${startTime}&endTime=${endTime}&page=${page}&limit=${limit}`;
		if (past) {
			url += "&sorting=DESC";
		}
		return this.http.get(url, this.authHttpOptions);
	}

	getCalendarBookings(
		userId: string,
		start: Date,
		end: Date
	): Observable<any> {
		const adjustedStart = new Date(start);
		adjustedStart.setHours(start.getHours() - 24);
		const startTime = adjustedStart.toISOString();

		const adjustedEnd = new Date(end);
		adjustedEnd.setHours(end.getHours() + 24);
		const endTime = adjustedEnd.toISOString();

		const url = `${BOOKING}/${userId}?startTime=${startTime}&endTime=${endTime}&page=1&limit=999`;

		return this.http.get(url, this.authHttpOptions);
	}

	getPastBookingsByMonth(
		data: GetPastBookingsData
	): Observable<BookingResponse> {
		const startTime = new Date(
			Date.UTC(data?.year, data?.month, 1)
		).toISOString();
		const endTime = new Date(
			Date.UTC(data?.year, data?.month + 1, 0, 23, 59, 59, 999)
		).toISOString();

		let url = `${BOOKING}/${data?.userId}?startTime=${startTime}&endTime=${endTime}&page=${data?.page}`;

		if (data?.limit) {
			url += `&limit=${data?.limit}`;
		}
		if (data?.studentId) {
			url += `&studentId=${data?.studentId}`;
		}
		if (data?.lessonType) {
			url += `&type=${data?.lessonType}`;
		}

		return this.http.get<BookingResponse>(url, this.authHttpOptions);
	}

	updateBooking(bookingId: string, data: object): Observable<any> {
		return this.http.put(
			`${BOOKING}/${bookingId}`,
			data,
			this.authHttpOptions
		);
	}

	deleteRegularLesson(teacherId: string, studentId: string): Observable<any> {
		return this.http.delete(
			`${BOOKING}/${teacherId}/${studentId}`,
			this.authHttpOptions
		);
	}

	getNextBookings(userId: string): Observable<any> {
		const currentDate = new Date();
		const oneDaysTime = new Date(
			currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
		);
		const startTime = currentDate.toISOString();
		const endTime = oneDaysTime.toISOString();
		return this.http.get(
			`${BOOKING}/${userId}?startTime=${startTime}&endTime=${endTime}&page=1&limit=5`,
			this.authHttpOptions
		);
	}

	startSession(booking, subrole) {
		const currentTarget =
			subrole === 3 ? booking.targetTeacher : booking.targetStudent;
		sessionStorage.bookingType = booking.type;
		if (subrole === 3) {
			sessionStorage.roomUrl = currentTarget;
			sessionStorage.shareLink = booking.targetStudent;
			// sessionStorage.studentId = booking.studentId;
		} else {
			// sessionStorage.teacherId = booking.teacherId;
			localStorage.removeItem("userName");
		}
		const roomLink = currentTarget.split("/").pop().replace(/\s*/g, "");

		const navigationExtras: NavigationExtras = {
			queryParams: {
				userId: booking.teacherId
			}
		};

		this.router.navigateByUrl(roomLink, navigationExtras).then(() => {
			if (subrole === 3) {
				if (localStorage.getItem("last_username")) {
					LessonsService.startSession$.next(true);
				}
			}
			sessionStorage.hidePassword = true;
			this.platformService.triggerLoader();
			if (this.roomState.hasFinishedCall$.getValue()) {
				window.location.reload();
			}
		});
	}

	rescheduleLesson(booking, timeZone) {
		const confirmData = {
			studentName: booking.student.name,
			bookingId: booking.id,
			student: booking.student,
			date: booking.startTime,
			duration: booking.duration
		};
		this.popupService.openRescheduleLessonDialog(confirmData, [
			{
				type: ButtonType.CANCEL,
				text: "lessons.dont-reschedule"
			},
			{
				type: ButtonType.OK,
				text: "lessons.reschedule",
				callBack: (dialog, textareaValue) => {
					const modal = dialog.getDialogById(
						"reschedule-lesson-dialog"
					);
					modal.close();
					this.popupService.dateAndTime(
						"reschedule",
						confirmData.student,
						"",
						confirmData.bookingId,
						textareaValue,
						timeZone
					);
				}
			}
		]);
	}

	createBookingRequest(bookingData: any): Observable<any> {
		return this.http.post(`${BOOKING}`, bookingData, this.authHttpOptions);
	}

	public createRegularLesson(userId, timeZone) {
		this.popupService.openSelectStudentDialog(userId, timeZone, [
			{
				type: ButtonType.CANCEL,
				text: ""
			},
			{
				type: ButtonType.OK,
				text: "lessons.continue-calendar-btn",
				callBack: (selectedStudent) => {
					this.popupService.dateAndTime(
						"booking",
						selectedStudent,
						userId
					);
				}
			}
		]);
	}

	deleteRepeatedBookings(
		teacherId: string,
		studentId: string,
		startTime?: string
	): Observable<any> {
		const url = DELETE_REPEAT_BOOKING.replace(
			":studentId",
			studentId
		).replace(":teacherId", teacherId);
		if (startTime !== null) {
			return this.http.delete(
				url + `?startTime=${startTime}`,
				this.authHttpOptions
			);
		} else {
			return this.http.delete(url, this.authHttpOptions);
		}
	}

	// we use this public endpoint on confirm booking page
	addTrialBookingPipedriveEndpoint(bookingData: any): Observable<any> {
		return this.http.post(`${PIPEDRIVE_BOOKING}`, bookingData, {
			headers: new HttpHeaders({
				"bot-key": environment.zapierKey,
				"bot-type": "zapier"
			})
		});
	}

	// we use this public endpoint on confirm booking page
	updateTrialBookingPipedriveEndpoint(
		bookingId: string,
		data: object
	): Observable<Booking> {
		return this.http.put<Booking>(`${BOOKING}/${bookingId}`, data, {
			headers: new HttpHeaders({
				"bot-key": environment.botkey,
				"bot-type": "webapp"
			})
		});
	}

	getTeacherDetails(teacherId: string): Observable<any> {
		return this.http.get(`${TEACHER_PUBLIC}/${teacherId}?firstName=1`, {
			headers: new HttpHeaders({
				"bot-key": environment.botkey,
				"bot-type": "webapp"
			})
		});
	}

	checkLeadBookings(
		pipeDriveId,
		startTime,
		endTime
	): Observable<BookingResponse> {
		const url = LEAD_BOOKING.replace(":pipedriveId", pipeDriveId);
		return this.http.get<BookingResponse>(
			url + `?startTime=${startTime}&endTime=${endTime}`,
			{
				headers: new HttpHeaders({
					"bot-key": environment.botkey,
					"bot-type": "webapp"
				})
			}
		);
	}

	isTrial(bookingType: string): Observable<boolean> {
		return of(bookingType === 'TRIAL');
	}

}
