import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { QualityRatingService } from "src/app/lm-quality-rating/services/quality-rating.service";
import { RoomAuthService } from "src/app/services/ws/room-auth.service";
import { LessonsCommunicationService } from "src/app/lessons-overview/services/lessons-communication.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { ButtonType } from "../../../call-room/data/ButtonType";
import { GetFeedback } from "../../../../types/get-feedback.type";
import { TranslateService, TranslateModule } from "@ngx-translate/core";

export type LessonData = {
	id: string;
	teacher: {
		id: string;
	};
	targetTeacher: string;
	manualAttendanceStatus: string;
};

@Component({
	selector: "app-lessons-summary",
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatSelectModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		FormsModule,
		TranslateModule
	],
	templateUrl: "./lessons-summary.component.html",
	styleUrl: "./lessons-summary.component.scss"
})
export class LessonsSummaryComponent {
	roomData;
	roomTitle;
	selectedEmoji: number | null = null;
	status: string = "";
	// content: string = "";
	feedbackSirius: string = "";
	// Validation
	emojiError: boolean = false;
	statusError: boolean = false;
	feedbackError: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public lessonData: LessonData,
		public dialogRef: MatDialogRef<LessonsSummaryComponent>,
		public roomService: RoomAuthService,
		private qualityRatingService: QualityRatingService,
		private lessonsCommunication: LessonsCommunicationService,
		private popupService: PopupService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.roomData = this.lessonData.targetTeacher;
		this.roomTitle = this.roomData.split("/")[3].split("?")[0];
		this.setStatus();

		this.qualityRatingService
			.getLessonFeedback(this.lessonData.id)
			.subscribe(
				(data: GetFeedback) => {
					if (data) {
						const { teacherFeedbackText, teacherRating } = data;
						this.feedbackSirius = teacherFeedbackText;
						this.selectedEmoji = teacherRating;
					}
				},
				(err) => {
					console.warn(err);
				}
			);
	}

	validateForm(): void {
		// this.emojiError = this.selectedEmoji === null;
		this.statusError = this.status === "";
		// this.feedbackError = this.feedbackSirius.trim() === "";
	}

	isFormValid(): boolean {
		return !this.emojiError && !this.statusError;
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onSubmit(): void {
		this.validateForm();

		if (!this.isFormValid()) {
			return;
		}

		const formData = {
			teacherRating: this.selectedEmoji,
			manualAttendanceStatus: this.status,
			// studentFeedbackText: this.content,
			teacherFeedbackText: this.feedbackSirius
		};
		// this.qualityRatingService
		// 	.getLessonFeedback(this.lessonData.id)
		// 	.subscribe((resp) => {
		// 		console.log(resp);
		// 	});
		this.qualityRatingService
			.postLessonFeedback(
				formData,
				this.roomTitle,
				this.lessonData.teacher.id,
				true
			)
			.subscribe(
				(resp) => {
					this.lessonsCommunication.notifyDataUpdated();
					this.dialogRef.close();
				},
				(err) => {
					const errorDescription = err.error;
					this.popupService.openCustomMessagePopup(
						this.getFriendlyErrorMessage(errorDescription),
						[
							{
								type: ButtonType.CANCEL,
								text: "Ok"
							}
						],
						false,
						true
					);
				}
			);
	}

	selectEmoji(value: number) {
		this.selectedEmoji = value;
		this.emojiError = false;
	}

	setStatus(): void {
		if (this.lessonData && this.lessonData.manualAttendanceStatus) {
			this.status = this.mapStatus(
				this.lessonData.manualAttendanceStatus
			);
		}
	}

	getFriendlyErrorMessage(error: any): string {
		if (error && error.description) {
			if (
				error.description.includes(
					"Cannot read properties of undefined"
				)
			) {
				return this.translate.instant("popUp.server-error");
			} else if (error.description.includes("Feedback for booking")) {
				return this.translate.instant("popUp.already-editeded");
			}
		}
		return this.translate.instant("popUp.generic-error");
	}

	mapStatus(manualAttendanceStatus: string): string {
		switch (manualAttendanceStatus) {
			case "Completed as planned":
				return "completed_as_planned";
			case "Student cancelled within 24 hours":
				return "student_cancelled_24_hours";
			case "Student didn't show up":
				return "student_not_appeared";
			case "Teacher canceled":
				return "teacher_canceled";
			case "This lesson was scheduled by mistake":
				return "scheduled_by_mistake";
			default:
				return manualAttendanceStatus;
		}
	}
}
