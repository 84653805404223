<mat-dialog-content>

	<p class="mb-2">{{'lessons.cancel-the-lesson' | translate}}</p>
	<p *ngIf="cancelingError"> Error </p>
	
	<div class="popup-text bg-red-200 p-2 mb-2">
	@if (data.bookingType == "SINGLE"){ 
		<p>{{'lessons.reschedule-notice' | translate}}</p>
	}
	@else if (data.bookingType == "TRIAL" && !startTimeUnder24Hours){
		<p>{{'lessons.cancel-trial-notice' | translate}}</p>
	}
	@else if (data.bookingType == "TRIAL" && startTimeUnder24Hours){
		<p>{{'lessons.cancel-trial-u24hours-notice' | translate}}</p>
	}
	
	</div>

	<div class="popup-text mb-2 ">

		@if (data.bookingType == "SINGLE"){ 
		<textarea style="border: 1px solid;border-radius: 5px; width: 100%;" rows="6" name="textarea" required
		placeholder="{{'lessons.cancel-placeholder' | translate}}"
		[formControl]="textareaForm.get('textarea')"></textarea>
		}

		@else if (data.bookingType == "TRIAL") { 
		<textarea style="border: 1px solid;border-radius: 5px; width: 100%;" rows="6" name="textarea" required
			placeholder="{{'lessons.cancel-trial-placeholder' | translate}}"
			[formControl]="textareaForm.get('textarea')"></textarea>
		}

	</div>
	

	<div class="justify-end flex">
		<button (click)="closeDialog()" mat-flat-button class="mr-2">Cancel</button>
		<button mat-flat-button color="warn" [disabled]="!textareaForm.get('textarea')?.value"
			(click)="cancelBooking()">{{'lessons.cancel-lesson'|translate}}</button>
	</div>

</mat-dialog-content>